import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./LoginBox.module.scss";
import { Button, Skeleton } from "antd";
import { ShowMessage } from "../ShowMessage/ShowMessage";
import http from "../../http";
import useOutsideClick from "../../hook/useOutsideClick";

const LoginBox = (
  props: {
    gotoLogin: (openid?: string) => void;
    loginSuccess: () => void;
  },
  ref: any
) => {
  const [isShow, setIsShow] = useState(false);
  const [qrLoading, setQrLoading] = useState(true);
  const [QRTimeOut, setQRTimeOut] = useState(false);
  const [ticket, setTicket] = useState("");
  const timer = useRef<NodeJS.Timeout | null>(null);
  const insiteClick = useRef(false)
  const loginBox = useRef<any>();
  useImperativeHandle(ref, () => {
    return {
      showLogin,
    };
  });
  useEffect(() => {
    if (isShow) {
      showWechat();
    } else {
      timer.current && clearTimeout(timer.current);
    }

    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, [isShow]);

  const showWechat = () => {
    setQrLoading(true);
    http.get("/api/v1/account/wechat/qr").then((res: any) => {
      if (res.status) {
        setTicket(res.data.ticket);
        checkLogin(res.data.ticket);
      } else {
        ShowMessage.error(res.message);
      }
      setQrLoading(false);
    });
  };
  const checkLogin = (ticket: any) => {
    let counter = 0; // 计时器初始化为0

    timer.current = setInterval(() => {
      counter++; // 每次轮询时计时器加1
      if (counter >= 20) {
        // 如果计时器达到20次
        setQRTimeOut(true);
        clearInterval(timer.current as NodeJS.Timeout); // 清除计时器
        return; // 退出函数
      }
      http
        .get("/api/v1/account/wechat/login?ticket=" + ticket)
        .then((res: any) => {
          counter < 20 && setQRTimeOut(false);
          setQrLoading(false);
          if (res.status) {
            if (res.data.openid) {
              // 未绑定手机
              props.gotoLogin(res.data.openid);
            } else if (res.data.token) {
              // 绑定过
              window.localStorage.setItem("token", res.data.token);
              props.loginSuccess();
            }
            close();
            clearInterval(timer.current as NodeJS.Timeout); // 登录成功后清除计时器
          }
        });
    }, 1000);
  };
  useOutsideClick(loginBox, () => {
    if(!insiteClick.current) {
      setIsShow(false);
    }
    insiteClick.current = false
  });
  const showLogin = () => {
    setIsShow(true);
  };
  const close = () => {
    setIsShow(false);
  };
  const timeOutClick = (isTimeOut: boolean) => {
    insiteClick.current = true
    if (isTimeOut) {
      setQrLoading(true);
      setQRTimeOut(false);
      checkLogin(ticket);
    }
  };
  return (
    <div
      className={styles.loginBox}
      style={isShow ? {} : { display: "none" }}
      ref={loginBox}
    >
      <div className={styles.describe}>
        <div className={styles.left}>
          <div className={styles.title}>更多功能</div>
          <div className={styles.function}>
            <div className={styles.umlIcon} />
            <div>UML图表</div>
          </div>
          <div className={styles.function}>
            <div className={styles.upLoadIcon} />
            <div>文件上传</div>
          </div>
          <div className={styles.function}>
            <div className={styles.loginIcon} />
            <div>多端登录</div>
          </div>
        </div>
        <div className={styles.right}>
          <div
            className={QRTimeOut ? styles.QRMask : styles.QRBox}
            onClick={() => timeOutClick(QRTimeOut)}
          >
            {qrLoading ? (
              <Skeleton.Image active={true} className={styles.wechat_qr_img} />
            ) : (
              <img
                className={styles.wechat_qr_img}
                src={`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${ticket}`}
              ></img>
            )}
          </div>
          <div className={styles.loginDescribe}>
            <div className={styles.title}>微信扫码注册/登录</div>
            <div>关注公众号即注册</div>
          </div>
        </div>
      </div>
      {/* <div className={styles.loginBtn}>
        <Button
          type="primary"
          shape="round"
          size="large"
          style={{ width: "100%" }}
          onClick={() => {
            props.gotoLogin();
            close();
          }}
        >
          立即登录
        </Button>
      </div> */}
    </div>
  );
};

export default forwardRef(LoginBox);
