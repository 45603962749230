import { useEffect } from 'react';

function useOutsideClick(ref: any, callback: any) {
  useEffect(() => {
    function handleClickOutside(event: { target: any; }) {
      if (ref.current && !ref.current?.contains(event.target)) {
        callback();
      }
    }

    // 绑定事件监听器
    document.addEventListener('click', handleClickOutside);
    return () => {
      // 在组件卸载时移除事件监听器
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, callback]);
}

export default useOutsideClick;
