import React from "react";
import styles from "../../styles/chat.module.scss";

export default function ChatCreatContent(props: {
  handleClick: (tip: string) => void;
}) {
  const { handleClick } = props;
  const tipsList = [
    "https://github.com/WordPress/WordPress的5.0和6.0版本中对于编辑器功能有了什么升级",
    "分析 https://github.com/vesoft-inc/nebula 中 Raft 算法的具体实现？",
  ];
  return (
    <div className={styles.chatContent}>
      <div className={styles.gptsDiv}>
        <img src="logo.png" alt="Product Logo" className={styles.productLogo} />
        <h2 className={styles.productTitle}>代码问题，就问Eagle！</h2>
        <p className={styles.productDescription}>
          Eagle是一个项目级代码分析AI，它支持对整个项目和跨项目的代码解读，解决开源项目理解、新项目上手和代码维护中的复杂问题。请把你需要解读的项目的GitHub链接及相关问题告诉我，也可以zip包的形式上传项目代码给我。（如果文件数量较多，Eagle对Github
          Repo的处理效果可能明显优于zip包形式）
        </p>
        <a
          href="https://mp.weixin.qq.com/s/BQ0Z5dfAW243m4noHBKiQw"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.guideLink}
        >
          Eagle用户使用帮助文档{">>"}
        </a>
      </div>
      <div className={styles.chatpdfTipsContainer}>
        {tipsList.map((item, index) => {
          return (
            <div
              key={index}
              className={styles.chatpdfTips}
              onClick={() => handleClick(item)}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
}
