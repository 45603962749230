import React, { useEffect, useRef, useState } from 'react'
import Tips from '../tips'
import { createRoot } from 'react-dom/client';
import styles from "../../styles/tips.module.scss"

export const ShowMessage = {
    success(content: any, duration?: number) {
        messageManager.addMessage(content, true, duration);
    },
    error(content: any, duration?: number) {
        messageManager.addMessage(content, false, duration);
    },
}
// Message Manager
const messageManager = {
    addMessage(content: any, isSuccess: boolean, duration?: number) {
        const container = document.createElement('div');
        document.body.appendChild(container);
        const root = createRoot(container);

        const onClose = () => {
            root.unmount();
            document.body.removeChild(container);
        };

        root.render(
            <Message isSuccess={isSuccess} message={content} duration={duration} onClose={onClose} />
        );
    }
};

// Message Component
const Message = (props: { isSuccess: boolean, message: string, duration?: number, onClose: () => void }) => {
    const { isSuccess, message, duration = 3000, onClose } = props
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    return (
        <div className={styles.message}>
            <div className={isSuccess ? styles.message_icon_dg : styles.message_icon_bc}></div>
            <p>{message}</p>
        </div>
    );
};