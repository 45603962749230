import React from 'react'
import { version } from '../../version'

export default function BottomLayer(props: {
    suffix?: string | React.ReactNode
    className?: string
}) {
    return (
        <div className={props.className}>{version}  京ICP备2024078129号-1 Copyright © 2024 <a href="https://www.capitalren.com" rel="noopener noreferrer" target="_blank">北京京兆人智能技术有限公司</a>{props?.suffix ? ` - ${props?.suffix}` : ''}</div>
    )
}
