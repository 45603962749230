import styles from "../../styles/login.module.scss";
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import http from "../../http";
import Wechat from "../Wechat";
import LoadingIcon from "../Icon/LoadingIcon";
import { ShowMessage } from "../ShowMessage/ShowMessage";
import { Checkbox, Modal, Radio } from "antd";

export async function getStaticProps() {
  // const todos = await fetch(
  //   "https://jsonplaceholder.typicode.com/todos"
  // ).then((response) => response.json());

  return {
    props: { type: "login" },
  };
}
const Login = (props: { loginSucccess: () => void }, ref: any) => {
  const { loginSucccess } = props;
  const [read, setRead] = useState(true);
  const [showFlag, setShowFlag] = useState("wechat_qr");
  const [ticket, setTicket] = useState("");
  const [openId, setOpenId] = useState("");
  const [QRTimeOut, setQRTimeOut] = useState(false);
  const [QRLoading, setQRLoading] = useState(true);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useImperativeHandle(ref, () => {
    return {
      show: (openid?: string) => {
        if (openid) {
          setShowFlag("wechat");
          setOpenId(openid);
        }
        setIsModalOpen(true);
      },
      close: () => {
        setIsModalOpen(false);
        setShowFlag("wechat_qr");
      },
    };
  });

  useEffect(() => {
    if (isModalOpen && showFlag === "wechat_qr") {
      showWechat();
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [isModalOpen, showFlag]);

  const showWechat = () => {
    setShowFlag("wechat_qr");
    http.get("/api/v1/account/wechat/qr").then((res: any) => {
      if (res.status) {
        setTicket(res.data.ticket);
        setQRLoading(false);
        checkLogin(res.data.ticket);
      } else {
        ShowMessage.error(res.message);
      }
    });
  };
  const openUserAgreement = () => {
    window.open("/userargreement", "_blank");
  };

  const openPrivacyPolicy = () => {
    window.open("/privacypolicy", "_blank");
  };
  const checkLogin = (ticket: any) => {
    let counter = 0; // 计时器初始化为0

    timer.current = setInterval(() => {
      counter++; // 每次轮询时计时器加1
      if (counter >= 20) {
        // 如果计时器达到20次
        setQRTimeOut(true);
        clearInterval(timer.current as NodeJS.Timeout); // 清除计时器
        return; // 退出函数
      }

      http
        .get("/api/v1/account/wechat/login?ticket=" + ticket)
        .then((res: any) => {
          counter < 20 && setQRTimeOut(false);
          setQRLoading(false);
          if (res.status) {
            if (res.data.openid) {
              // 未绑定手机
              setOpenId(res.data.openid);
              setShowFlag("wechat");
            } else if (res.data.token) {
              // 绑定过
              window.localStorage.setItem("token", res.data.token);
              loginSucccess();
            }
            clearInterval(timer.current as NodeJS.Timeout); // 登录成功后清除计时器
          } else {
          }
        });
    }, 1000);
  };
  const timeOutClick = (isTimeOut: boolean) => {
    if (isTimeOut) {
      setQRLoading(true);
      setQRTimeOut(false);
      checkLogin(ticket);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setShowFlag("wechat_qr");
  };
  return (
    <Modal
      title=""
      open={isModalOpen}
      maskClosable={true}
      closable={false}
      footer={null}
      style={{minWidth: "40vw"}}
      centered
      onCancel={handleCancel}
    >
      <div className={styles.login}>
        <div className={styles.login_body}>
          <div className={styles.login_content}>
            <div className={styles.login_form}>
              {showFlag == "wechat_qr" && (
                <div className={styles.userLoginBox}>
                  <div
                    className={
                      showFlag == "wechat_qr"
                        ? styles.weChatIcon
                        : styles.weChatIcon
                    }
                    // onClick={loginTypeChange}
                  ></div>
                  {showFlag == "wechat_qr" && (
                    <>
                      <h2>微信扫码注册/登录</h2>
                      <div className={styles.desc}>关注公众号即注册</div>
                      <div
                        className={QRTimeOut ? styles.QRMask : styles.QRBox}
                        onClick={() => timeOutClick(QRTimeOut)}
                      >
                        {QRLoading ? (
                          <LoadingIcon size={72} />
                        ) : (
                          <img
                            className={styles.wechat_qr_img}
                            src={`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${ticket}`}
                          ></img>
                        )}
                      </div>
                      <Checkbox
                        onChange={(e) => {
                          setRead(e.target.checked);
                        }}
                        checked={read}
                        style={{ marginTop: 16 }}
                      >
                        阅读并接受{" "}
                        <span
                          className={styles.link}
                          onClick={openUserAgreement}
                        >
                          &lt;用户协议&gt;
                        </span>
                        <span className={styles.conjunction}>和</span>
                        <span
                          className={styles.link}
                          onClick={openPrivacyPolicy}
                        >
                          &lt;隐私政策&gt;
                        </span>
                      </Checkbox>
                    </>
                  )}
                </div>
              )}
              {showFlag == "wechat" && (
                <Wechat openId={openId} onSuccess={loginSucccess}></Wechat>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default forwardRef(Login);
