export const SuffixCorrespondencemap:Record<string, string> = {
    "py": "python",
    "js": "javascript",
    "ts": "typescript",
    "jsx": "javascript",
    "tsx": "typescript",
    "java": "java",
    "c": "c",
    "cpp": "cpp",
    "cs": "csharp",
    "rb": "ruby",
    "go": "go",
    "php": "php",
    "html": "html",
    "htm": "html",
    "css": "css",
    "scss": "scss",
    "less": "less",
    "json": "json",
    "xml": "xml",
    "yml": "yaml",
    "yaml": "yaml",
    "sh": "bash",
    "bash": "bash",
    "zsh": "zsh",
    "bat": "batch",
    "cmd": "batch",
    "sql": "sql",
    "tsql": "sql",
    "psql": "sql",
    "plsql": "sql",
    "rs": "rust",
    "kt": "kotlin",
    "kts": "kotlin",
    "swift": "swift",
    "md": "markdown",
    "r": "r",
    "m": "matlab",
    "mat": "matlab",
    "pl": "perl",
    "pm": "perl",
    "lua": "lua",
    "hs": "haskell",
    "erl": "erlang",
    "ex": "elixir",
    "exs": "elixir",
    "dart": "dart",
    "sc": "scala",
    "scala": "scala",
    "groovy": "groovy",
    "gradle": "groovy",
    "jl": "julia",
    "v": "verilog",
    "sv": "systemverilog",
    "vhd": "vhdl",
    "vhdl": "vhdl",
    "ada": "ada",
    "adb": "ada",
    "ads": "ada",
    "ml": "ocaml",
    "mli": "ocaml",
    "fs": "fsharp",
    "fsi": "fsharp",
    "fsx": "fsharp",
    "fsscript": "fsharp",
    "nim": "nim",
    "pas": "pascal",
    "pp": "pascal",
    "p": "pascal",
    "d": "d",
    "asm": "assembly",
    "s": "assembly",
    "f": "fortran",
    "f90": "fortran",
    "f95": "fortran",
    "f03": "fortran",
    "f08": "fortran",
    "lisp": "lisp",
    "lsp": "lisp",
    "clj": "clojure",
    "cljc": "clojure",
    "cljs": "clojurescript",
    "edn": "clojure",
    "rkt": "racket",
    "rktd": "racket",
    "rktl": "racket",
    "rktz": "racket",
    "purs": "purescript",
    "elm": "elm",
    "coffee": "coffeescript",
    "litcoffee": "coffeescript",
    "hx": "haxe",
    "hxsl": "haxe",
    "hxproj": "haxe",
    "sol": "solidity",
    "proto": "protobuf",
    "pb": "protobuf",
    "pbtxt": "protobuf",
    "tcl": "tcl",
    "tk": "tcl",
    "cr": "crystal",
    "eex": "elixir",
    "leex": "elixir",
    "heex": "elixir",
    "tf": "terraform",
    "tfvars": "terraform",
    "tfstate": "terraform",
    "hcl": "hcl",
    "rego": "rego",
    "cue": "cue",
    "bicep": "bicep",
    "q": "q",
    "k": "k",
    "p4": "p4",
    "mjs": "javascript",
    "cjs": "javascript",
    "cts": "typescript",
    "mts": "typescript",
    "glsl": "glsl",
    "frag": "glsl",
    "vert": "glsl",
    "wgsl": "wgsl",
    "sqlx": "sql",
    "prisma": "prisma"
  }
  