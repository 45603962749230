// hook/useManualServerSentEvents.ts
import { useCallback, useEffect, useState, useRef } from "react";
import { CodeFileType, TreeData } from "../components/tree/CodeTree";
import http from "../http";
import { reMadeTree } from "../utils/util";
import { ShowMessage } from "../components/ShowMessage/ShowMessage";
import Router, { useRouter } from "next/router";

export const useManualServerSentEvents = (
  url: string,
  body: any,
  clearFileId: any,
  setStop: any,
  setFlag: any,
  getInfo: any,
  resetFn: any,
  headers?: HeadersInit
) => {
  const [messages, setMessages] = useState({ value: "", pending: false });
  const [controller, setController] = useState<AbortController | null>(null);
  const [treeData, setTreeData] = useState<TreeData[]>([]);
  const [codeData, setcodeData] = useState<{
    branch_name: string;
    repo_full_name: string;
  }>({
    branch_name: "",
    repo_full_name: "",
  });
  const router = useRouter();
  const [responseState, setResponseState] = useState<number>();
  const [isoutputing, setIsOutputing] = useState(false);
  const clearTag = useRef(true);
  const accumulatedContent = useRef("");
  const startFetching = useCallback(
    (data: any) => {
      const newController = new AbortController();
      setController(newController);
      const signal = newController.signal;
      // debugger
      const fetchData = async () => {
        try {
          setIsOutputing(true);
          clearFileId();
          const response: any = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ...headers,
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(data),
            signal,
          });
          // 记录请求返回状态
          setResponseState(Number(response.status));
          // debugger
          if (response.body) {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let accumulatedStr = "";
            let str = "";
            accumulatedContent.current = "";
            while (true) {
              const { done, value } = await reader.read();

              if (done) {
                setMessages({
                  value: accumulatedContent.current,
                  pending: false,
                });
                clearTag.current = true; //清空上一次的内容
                accumulatedContent.current = "";
                resetFn();
                const ids = JSON.parse(
                  localStorage.getItem("threadIds") || "[]"
                );
                const newIds = ids.filter((item: any) => {
                  return item !== data.thread_id;
                });
                localStorage.setItem("threadIds", JSON.stringify(newIds));
                setIsOutputing(false);
                break;
              }
              accumulatedStr += decoder.decode(value, { stream: true });
              const lines = accumulatedStr.split("\nEND_OF_MESSAGE\n");
              accumulatedStr = lines.pop() || ""; // 保存最后的部分，如果是未完成的 JSON 对象
              for (const line of lines) {
                if (line.trim() !== "") {
                  try {
                    const parsed = JSON.parse(line);
                    // console.log(clearTag.current,'-----------------',parsed.content)
                    if (parsed.type === "message") {
                      accumulatedContent.current += parsed.content;
                      // setMessages((prevMessages: any) => {
                      //    return clearTag.current? ('哈哈哈哈' + parsed.content):(prevMessages + parsed.content)
                      // });
                      // clearTag.current=false
                    } else if (parsed.type === "error") {
                      accumulatedContent.current =
                        "对话出错，请刷新网页或者重建对话重试";
                    }
                  } catch (error) {
                    console.error("Error parsing JSON:", error);
                  }
                }
              }
              // console.log('??????????????????????',accumulatedContent)
              setMessages({ value: accumulatedContent.current, pending: true });
            }
            // 处理剩余的未完成的 JSON 对象
            if (accumulatedStr.trim() !== "") {
              try {
                const parsed = JSON.parse(accumulatedStr);
                if (parsed.type === "message") {
                  console.log(parsed.content);
                  // setMessages((prevMessages: any) => prevMessages + parsed.content);
                  setMessages((prevMessages: any) => {
                    console.log(prevMessages);
                    return {
                      value: prevMessages + parsed.content,
                      pending: true,
                    };
                  });
                } else if (parsed.type === "error") {
                  setStop(false);
                  ShowMessage.error("对话出错，请刷新网页或者重建对话重试");
                  // setMessages(parsed.message)
                  setMessages({
                    value: "对话出错，请刷新网页或者重建对话重试",
                    pending: false,
                  });
                } else if (parsed.code === 400) {
                  //对话不存在
                  setStop(false);
                  ShowMessage.error(parsed.message);
                  // setMessages(parsed.message)
                  setMessages({ value: parsed.message, pending: false });
                } else if (parsed.code === 402) {
                  //余额不足
                  getInfo();
                  setStop(false);
                  ShowMessage.error(parsed.message);
                  // setMessages(parsed.message)
                  setMessages({ value: parsed.message, pending: false });
                  setFlag(true);
                } else if (parsed.code === 500) {
                  getInfo();
                  setStop(false);
                  ShowMessage.error(parsed.message);
                }
              } catch (error) {
                console.error("Error parsing remaining JSON:", error);
              }
            }
            // 获取代码树
            getCodeData(body.thread_id);
          }
        } catch (error: any) {
          if (error.name === "AbortError") {
            // Fetch was aborted
            console.log("Fetch aborted");
          } else {
            console.error("Fetch error:", error);
          }
        }
      };

      fetchData();
    },
    [url, body, headers]
  );
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (body.thread_id && token) {
      getCodeData(body.thread_id);
    }
  }, [body.thread_id]);

  const getCodeData = (thread_id: string) => {
    http.get(`/api/v1/chat/code-structure?thread_id=${thread_id}`).then(
      (data: any) => {
        if (data.status) {
          setcodeData({
            branch_name: data.data?.branch_name || "",
            repo_full_name: data.data?.repo_full_name || "",
          });
          setTreeData(reMadeTree(data.data.code_structure || []));
        } else {
          ShowMessage.error(data.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const stopFetching = useCallback(() => {
    if (controller) {
      controller.abort();
      setController(null);
    }
  }, [controller]);
  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [controller]);

  return {
    messages,
    startFetching,
    stopFetching,
    setMessages,
    treeData,
    codeData,
    responseState,
    isoutputing,
    setIsOutputing,
  };
};
