import React from 'react'
import styles from './iconStyles.module.scss'

export default function LoadingIcon(props: {
  size?: number
  style?: React.CSSProperties
}) {
  return (
    <div style={props.size?{width: props.size,height: props.size,...props.style}:{...props.style}} className={styles.loadingIcon}></div>
  )
}
