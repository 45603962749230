import { Tooltip } from "antd";
import React from "react";

export default function Change_theme_icon(props: {
  theme: "dark" | "light" | string;
  onClick: (theme: "dark" | "light") => void;
  classname?: string;
}) {
  const { theme, onClick, classname } = props;
  return (
    <Tooltip
      placement="bottom"
      title={theme === "dark" ? "亮色主题" : "深色主题"}
      autoAdjustOverflow
    >
      <div
        onClick={() => {
          onClick(theme === "dark" ? "light" : "dark");
        }}
        className={classname}
      >
        {theme === "dark" ? (
          <svg
            className="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="10223"
            width="24"
            height="24"
          >
            <path
              d="M512 128c24.256 0 43.904 19.648 43.904 43.904v54.848a43.904 43.904 0 0 1-87.808 0v-54.848C468.096 147.648 487.744 128 512 128z m0 625.344c24.256 0 43.904 19.648 43.904 43.904v54.848a43.904 43.904 0 1 1-87.808 0v-54.848c0-24.256 19.648-43.904 43.904-43.904zM896 512a43.904 43.904 0 0 1-43.904 43.904h-54.848a43.904 43.904 0 1 1 0-87.808h54.848c24.256 0 43.904 19.648 43.904 43.904zM270.656 512a43.904 43.904 0 0 1-43.904 43.904h-54.848a43.904 43.904 0 0 1 0-87.808h54.848c24.256 0 43.904 19.648 43.904 43.904z m512.896-271.552a43.904 43.904 0 0 1 0 62.08l-38.784 38.784a43.904 43.904 0 1 1-62.08-62.08l38.784-38.784a43.904 43.904 0 0 1 62.08 0z m-442.24 442.24a43.904 43.904 0 0 1 0 62.08l-38.784 38.784a43.904 43.904 0 1 1-62.08-62.08l38.784-38.784a43.904 43.904 0 0 1 62.08 0z m442.24 100.864a43.904 43.904 0 0 1-62.08 0l-38.784-38.784a43.904 43.904 0 1 1 62.08-62.08l38.784 38.784a43.904 43.904 0 0 1 0 62.08z m-442.24-442.24a43.904 43.904 0 0 1-62.08 0l-38.784-38.784a43.904 43.904 0 1 1 62.08-62.08l38.784 38.784a43.904 43.904 0 0 1 0 62.08zM512 676.544a164.544 164.544 0 1 1 0-329.152 164.544 164.544 0 0 1 0 329.152z"
              fill="#ffffff"
              p-id="10224"
            ></path>
          </svg>
        ) : (
          <svg
            className="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="11338"
            width="24"
            height="24"
          >
            <path
              d="M512 0c282.770286 0 512 229.229714 512 512s-229.229714 512-512 512S0 794.770286 0 512 229.229714 0 512 0z m0 97.52381C283.087238 97.52381 97.52381 283.087238 97.52381 512s185.563429 414.47619 414.47619 414.47619 414.47619-185.563429 414.47619-414.47619S740.912762 97.52381 512 97.52381z m-153.648762 165.449142A316.586667 316.586667 0 0 0 341.333333 365.714286c0 175.055238 141.897143 316.952381 316.952381 316.952381 35.961905 0 70.509714-5.997714 102.716953-17.017905A292.327619 292.327619 0 0 1 512 804.571429c-161.572571 0-292.571429-130.998857-292.571429-292.571429 0-105.22819 55.53981-197.485714 138.922667-249.027048z"
              fill="#2c2c2c"
              p-id="11339"
            ></path>
          </svg>
        )}
      </div>
    </Tooltip>
  );
}
