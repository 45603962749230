import styles from "../styles/login.module.scss";
import { useRef, useState, useEffect } from "react";
import http from "../http";
import { ShowMessage } from "./ShowMessage/ShowMessage";
import { Button } from "antd";

export default function Wechat(props: {
  openId: string;
  onSuccess: () => void;
}) {
  const { openId, onSuccess } = props;
  const phone = useRef<HTMLInputElement>({ value: "" } as any);
  const [read, setRead] = useState(true);
  const code = useRef<HTMLInputElement>({ value: "" } as any);
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false)
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      login();
    }
  };
  const login = () => {
    if (!read) {
      ShowMessage.error("请同意协议");
      return;
    }
    let number = phone.current.value;
    let codeValue = code.current.value;
    if (!number) {
      ShowMessage.error("请输入手机号");
      return;
    }
    if (!codeValue) {
      ShowMessage.error("请输入验证码");
    }
    http
      .post("/api/v1/account/wechat/bind/", {
        phone_number: number,
        sms_code: codeValue,
        openid: openId,
        bd_vid: sessionStorage.getItem("bd_vid") || "",
      })
      .then((res: any) => {
        if (res.status) {
          window.localStorage.setItem("token", res.data.token);
          http.post(`/api/v1/account/utm-source `, {
            utm_source: sessionStorage.getItem("utm_source") || "",
            openid: openId,
          });
          onSuccess();
        } else {
          ShowMessage.error(res.message);
        }
        setLoginLoading(false)
      });
  };
  const getCode = () => {
    let number = phone.current.value;
    if (!number) {
      ShowMessage.error("请输入手机号");
      return;
    } else if (!/^1[3456789]\d{9}$/.test(number)) {
      ShowMessage.error("请输入正确的手机号");
      return;
    }
    setSeconds(60);
    setIsActive(true);
    http
      .post("/api/v1/account/code/", { phone_number: number })
      .then((res: any) => {
        if (res.status) {
        } else {
          ShowMessage.error(res.message);
        }
      });
  };
  useEffect(() => {
    let interval: any = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => {
          return seconds - 1;
        });
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  return (
    <div className={styles.weChatBox}>
      <h2 className={`${styles.wechat} ${styles.title}`}>
        新注册微信用户请绑定手机号
      </h2>
      <div className={styles.form}>
        <div className={styles.info_part}>
          <div className={styles.info_label}>
            手机号<sup>*</sup>
          </div>
          <input ref={phone} placeholder="请输入您的手机号" />
        </div>
        <div className={styles.info_part}>
          <div className={styles.info_label}>
            验证码<sup>*</sup>
          </div>
          <input
            ref={code}
            placeholder="请输入短信验证码"
            type="text"
            onKeyPress={handleKeyPress}
          />
          <div className={styles.send_code}>
            {/* 发送验证码 */}
            {isActive && <div>{seconds}s</div>}
            {!isActive && <button onClick={getCode}>发送验证码</button>}
          </div>
        </div>
        <Button
          type="primary"
          shape="round"
          size={"large"}
          onClick={() => {
            setLoginLoading(true)
            login();
          }}
          style={{ marginTop: "56px", width: "100%" }}
          loading={loginLoading}
        >
          登录
        </Button>
      </div>
    </div>
  );
}
